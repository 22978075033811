
// @use '@angular/material' as mat;
// @use '@angular/material/theming' as mat-theming;

// @include mat-theming.core();

// $angular-primary: mat.define-palette(mat.$teal-palette, 500, 100, 900);
// $angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $angular-warn: mat.define-palette(mat.$red-palette);

// $angular-default-theme: mat-theming.define-light-theme(
//   (
//     color: (
//       primary: $angular-primary,
//       accent: $angular-accent,
//       warn: $angular-warn,
//     ),
//   )
// );

// @include mat-theming.all-component-themes($angular-default-theme);

// $angular-dark-theme: mat-theming.define-dark-theme(
//   (
//     color: (
//       primary: $angular-primary,
//       accent: $angular-accent,
//       warn: $angular-warn,
//     ),
//   )
// );

// .darkMode {
//   @include mat-theming.all-component-colors($angular-dark-theme);
// }


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; width: 100%;}
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-x: hidden; 
}

.d-f-c {
  display: flex;
  align-items: center;
}